@media (max-width: 1279.9px) {
	.devices {
		&_lid {
			padding: 1%;
			border-radius: 8px;
			border: 3px solid $coolGray-500;
			&::before,
			&::after {
				content: unset;
			}
			&_topEdge {
				display: none;
			}
		}
		&_base {
			display: none;
		}
	}
}
