@import "../../_init/colors/colors";
.header-light {
	.header {
		&_title .text {
			color: $coolGray-800;
		}
		&_navigator {
			.navigator {
				.list .list_item {
					.button {
						background-color: transparent;
						&::before {
							background: linear-gradient(
								to top right,
								$summer-sky,
								$sky-400
							);
						}
						.text {
							color: $coolGray-800;
						}
					}
					&-active {
						@extend .list_item;
					}
				}
			}

			.switcher {
				.switcher_slider {
					background: transparent;
					box-shadow: inset 0 0 0 1px $coolGray-400;
					.icon {
						background-color: $coolGray-400;
						svg {
							color: $coolGray-50;
						}
					}
				}
			}
		}
	}
}
@media (min-width: 1280px) {
	.header {
		&_navigator {
			.navigator {
				.list {
					.list_item {
						.button:hover {
							.text {
								color: $coolGray-100;
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 768px) and (max-width: 1279.9px) {
	.header {
		&_navigator {
			.navigator {
				.list {
					.list_item {
						&-active {
							.button {
								.text {
									color: $coolGray-200 !important;
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (max-width: 767.9px) {
	.header-light {
		.header_navigator {
			.navigator {
				background-color: $coolGray-50;
				border-top: 1px solid $coolGray-200;
				.list {
					.list_item {
						.button {
							svg {
								color: $coolGray-800 !important;
							}
						}
						&-active {
							.button {
								svg {
									color: $summer-sky !important;
								}
							}
						}
					}
				}
			}
		}
	}
}
