@font-face {
	font-family: poppins;
	src: url(../../_init/fonts/Poppins/Poppins-Regular.ttf);
}
@import "../../_init/colors/colors";
.text {
	position: relative;
	transition: 0.4s;
	font-family: poppins;
	&-active {
		color: $blue-500;
	}
	&-tag {
		padding: 0.4em 0.8em;
		border-radius: 0.3em;
		display: block;
		background: $blue-200;
		color: $blue-900;
	}
}
