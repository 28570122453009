@import "../../_init/colors/colors";
.header {
	&-dark {
		.header {
			&_title .text {
				color: $coolGray-200;
			}
			&_navigator {
				.navigator {
					.list .list_item {
						.button {
							background-color: transparent;
							&::before {
								background: linear-gradient(
									to top right,
									$summer-sky,
									$sky-400
								);
							}
							.text {
								color: $coolGray-100;
							}
						}
						&-active {
							@extend .list_item;
						}
					}
				}
				.switcher {
					.switcher_slider {
						background: transparent;
						box-shadow: inset 0 0 0 1px $coolGray-300;
						.icon {
							background-color: $coolGray-300;
							svg {
								color: $coolGray-800;
							}
						}
					}
				}
			}
		}
	}
}
@media (max-width: 767.9px) {
	.header-dark {
		.header_navigator {
			.navigator {
				background-color: $coolGray-800;
				border-top: 1px solid $coolGray-700;
				.list {
					.list_item {
						.button {
							svg {
								color: $coolGray-200 !important;
							}
						}
						&-active {
							.button {
								svg {
									color: $summer-sky !important;
								}
							}
						}
					}
				}
			}
		}
	}
}
