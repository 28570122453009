@import "../../../_init/animation/animations";
@import "../../../_init/fonts/fonts";

.contact {
	&_title {
		grid-area: 5/4/7/12;
		font-family: visueltPro;
		font-size: calc(1.5 * var(--section-unit));
		font-weight: 500;
		line-height: calc(1.8 * var(--section-unit));
		justify-self: center;
		align-self: center;
		text-align: center;
		&::before {
			content: "?";
			position: absolute;
			bottom: 0;
			right: -1ch;
		}
	}
	&_paragraph {
		grid-area: 7/5/10/11;
		font-family: poppins;
		font-size: calc(0.45 * var(--section-unit));
		font-weight: 100;
		justify-self: center;
		align-self: center;
		text-align: center;
		.button {
			display: inline-block;
			background: transparent;
			font-family: poppins;
			font-size: calc(0.45 * var(--section-unit));
			font-weight: 500;
			user-select: unset;
			&::before {
				content: "";
				position: absolute;
				width: 0;
				height: 0.1em;
				bottom: 0.05em;
				right: 0;
				background-color: currentColor;
				transition: all 0.4s, background-color 0s;
			}
			&:hover {
				transform: translateY(0);
				&::before {
					right: unset;
					left: 0;
					width: 100%;
				}
			}
		}
	}
	&_list {
		grid-area: 10/4/11/12;
		align-self: center;
		justify-self: center;
		display: flex;
		gap: calc(1 * var(--section-unit));
		.list_item {
			.button {
				background: transparent;
				padding: 0.4em;
				border-radius: 0.4em;
				.icon {
					display: flex;
					justify-content: center;
					align-items: center;
					svg {
						transition: all 0.4s;
						font-size: calc(1.1 * var(--section-unit));
					}
				}
				&:hover {
					svg {
						transform: scale(1.1);
					}
				}
			}
		}
	}
}
@import "./contact.media.scss";
