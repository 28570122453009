.navigator {
	display: inline-block;
	.list {
		display: flex;
		justify-content: center;
		align-items: center;
		&_item {
			.text {
				opacity: 0.5;
			}
			&:hover {
				.text {
					opacity: 1;
				}
			}
			&-active {
				.text {
					opacity: 1;
				}
			}
		}
	}
}
