@media (min-width: 768px) {
	.header {
		&_navigator {
			.navigator {
				.list {
					&_item,
					%lst_itm_mi768 {
						.button {
							border-radius: calc(0.45 * var(--header-unit));
							font-family: poppins;
							font-size: calc(0.45 * var(--header-unit));
							font-weight: 300;
							&::before {
								content: "";
								font-size: inherit;
								transition: all 0.4s, font-size 0s;
								transform: translate(-50%, 50%);
								position: absolute;
								left: 50%;
								width: 0.36em;
								aspect-ratio: 1/1;
								border-radius: 50%;
								z-index: 0;
								opacity: 0;
							}
							svg {
								display: none;
							}
							.text {
								padding: calc(0.225 * var(--header-unit))
									calc(0.6 * var(--header-unit));
							}
						}
						&-active {
							@extend %lst_itm_mi768;
							.button {
								&::before {
									opacity: 1 !important;
								}
								.text {
									text-decoration: none;
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 1280px) {
	.header {
		&_navigator {
			.list {
				&_item,
				%nvg_lst_itm_mi1024 {
					.button {
						&::before {
							bottom: 0.18em;
						}
						&:hover::before {
							opacity: 1;
							width: 120%;
							bottom: 50%;
						}
					}
					&-active {
						@extend %nvg_lst_itm_mi1024;
						.button {
							&::before {
								opacity: 1;
							}
							.text {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 768px) and (max-width: 1279.9px) {
	.header {
		&_navigator {
			.list {
				.list_item,
				%navigator_list_item_mi768_max1023 {
					.button {
						&::before {
							bottom: 50%;
						}
					}
					&-active {
						@extend %navigator_list_item_mi768_max1023;
						.button {
							.text {
								opacity: 1;
								text-decoration: none;
							}
							&::before {
								opacity: 1;
								width: 120% !important;
							}
						}
					}
				}
			}
		}
	}
}
@media (max-width: 767.9px) {
	.header {
		&_navigator {
			.navigator {
				position: fixed;
				bottom: 0;
				left: 0;
				width: 100%;
				height: calc(1.5 * var(--header-unit));
				transition: 0.4s;
				.list {
					height: 100%;
					justify-content: space-evenly;
					&_item,
					%lst_itm_ma767 {
						.button {
							display: flex;
							align-items: center;
							justify-content: center;
							svg {
								transition: 0.4s;
								font-size: calc(0.65 * var(--header-unit));
								opacity: 0.5;
							}
							.text {
								display: none;
							}
						}
						&-active {
							@extend %lst_itm_ma767;
							.button {
								flex-direction: column;
								overflow: unset;
								transform: scale(1.2);
								svg {
									opacity: 1 !important;
								}
							}
						}
					}
				}
			}
		}
	}
}
