@import "../../../_init/animation/animations";
@import "../../../_init/fonts/fonts";
.home {
	&_title {
		user-select: none;
		grid-area: 4/2/8/14;
		justify-self: start;
		align-self: end;
		margin-bottom: 1vw;
		z-index: 5;
		sub {
			font-family: visueltPro;
			font-size: calc(0.75 * var(--section-unit));
			font-weight: 500;
			line-height: calc(0.75 * var(--section-unit));
			height: calc(0.75 * var(--section-unit));
			transition: 0.4s;
		}
		h1 {
			transition: 0.4s;
			font-family: visueltPro;
			font-size: calc(3 * var(--section-unit));
			font-weight: 500;
			word-spacing: calc(0.8 * var(--section-unit));
			letter-spacing: calc(-0.3 * var(--section-unit));
			left: calc(-0.2 * var(--section-unit));
		}
	}
	&_content {
		grid-area: 7/2/10/11;
		justify-self: start;
		align-self: center;
		text-align: justify;
		font-family: poppins;
		font-size: calc(0.45 * var(--section-unit));
		font-weight: 100;
		z-index: 5;
	}
	&_buttonGroup {
		grid-area: 9/2/12/11;
		justify-self: start;
		align-self: start;
		margin-top: 2.5vw;
		display: flex;
		gap: calc(0.5 * var(--section-unit));
		z-index: 5;
		transition: 0.4s;
		.button {
			&-cta,
			&-ghost {
				background: transparent;
				overflow: unset;
				font-size: calc(0.55 * var(--section-unit));
				.text {
					font-family: visueltPro;
					font-size: 1em;
					font-weight: 300;
					padding: calc(0.5 * var(--section-unit))
						calc(1 * var(--section-unit));
					display: flex;
					svg {
						margin-left: calc(0.1 * var(--section-unit));
					}
				}
				&::before,
				&::after {
					content: "";
					position: absolute;
					inset: 0;
					transition: 0.4s;
					border-radius: 0.15em;
				}
				&::after {
					z-index: 0;
				}
				&:hover {
					.text {
						transform: scale(1.1);
					}
					&::after {
						inset: -6px;
					}
				}
			}
		}
	}
}
@import "./home.media.scss";
