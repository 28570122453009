@import "../../../_init/colors/colors";
.home.section-light {
	background-color: $coolGray-50;
	.home {
		&_title {
			sub {
				color: $coolGray-800;
			}
			h1 {
				color: $coolGray-800;
				text-shadow: -2px 2px 0 $summer-sky;
			}
		}
		&_content {
			color: $coolGray-600;
		}
		&_buttonGroup {
			.button {
				&-cta {
					.text {
						color: $coolGray-200;
					}
					&::before {
						background: linear-gradient(
							to top right,
							$summer-sky,
							$sky-400
						);
					}
					&::after {
						box-shadow: inset 0 0 0 0px $summer-sky;
					}
					&:hover {
						&::after {
							box-shadow: inset 0 0 0 2px $summer-sky;
						}
					}
				}
				&-ghost {
					.text {
						color: $coolGray-800;
					}
					&::before {
						box-shadow: inset 0 0 0 2px $summer-sky;
					}
					&::after {
						box-shadow: inset 0 0 0 0px $summer-sky;
					}
					&:hover {
						&::after {
							box-shadow: inset 0 0 0 2px $summer-sky;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 961.9px) {
	.home {
		&_title {
			sub {
				color: $coolGray-200 !important;
			}
			h1 {
				color: $coolGray-200 !important;
				text-shadow: unset !important;
			}
		}
		&_content {
			color: $coolGray-200 !important;
		}
		&_buttonGroup {
			.button {
				&-ghost {
					.text {
						color: $coolGray-200 !important;
					}
				}
			}
		}
	}
}
