@import "../../../_init/animation/animations";
.portrait {
	pointer-events: none;
	position: fixed;
	width: calc(13 * var(--portrait-unit));
	height: 100%;
	transition: 0.4s;
	overflow: hidden;
	z-index: 1;
	img {
		transition: 0.4s;
		position: absolute;
		width: calc(12 * var(--portrait-unit));
		left: 0;
		bottom: 0;
		user-select: none;
	}
}
@import "./portrait.media.scss";
