@import "../../_init/fonts/fonts";
@import "../../_init/animation/animations";
.header {
	padding: var(--header-unit) 0rem;
	display: grid;
	grid-template-columns: calc(2 * var(--header-unit)) repeat(12, 1fr) calc(
			2 * var(--header-unit)
		);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	&_title {
		grid-column: 2/-2;
		grid-row: 1/1;
		justify-self: start;
		align-self: center;
		width: 100%;
		height: calc(0.9 * var(--header-unit));
		overflow: hidden;
		.list {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			.list_item {
				height: calc(0.9 * var(--header-unit));
				.text {
					display: block;
					font-family: poppins;
					font-size: calc(0.65 * var(--header-unit));
					font-weight: 500;
					user-select: none;
				}
			}
		}
	}
	&_navigator {
		grid-column: 2/-2;
		grid-row: 1/1;
		justify-self: end;
		align-self: center;
		display: flex;
		align-items: center;
		gap: calc(0.75 * var(--header-unit));
		.navigator {
			.list {
				gap: calc(0.75 * var(--header-unit));
			}
		}
		.switcher {
			font-size: calc(0.9 * var(--header-unit));
		}
	}
}
@import "./header.media.scss";
