@media (min-width: 1024px) and (max-width: 1279.9px) {
	.portrait {
		img {
			bottom: -5rem;
		}
	}
}
@media (max-width: 961.9px) {
	.portrait {
		width: unset;
		inset: 0 !important;
		background: transparent !important;
		&::after {
			content: "";
			position: absolute;
			inset: 30% 0 0 0;
		}
	}
}

@media (min-width: 768px) and (max-width: 961.9px) {
	.portrait {
		img {
			width: calc(60% + 8 * var(--portrait-unit));
		}
	}
}
@media (max-width: 767.9px) {
	@mixin on-scroll-animation {
		@for $i from 0 through 75 {
			.app.scroll-#{25 + $i} {
				@if $i >= 12 {
					.portrait {
						transform: translateX(-25vw);
						opacity: 0;
					}
				}
			}
		}
	}
	@include on-scroll-animation();
}
@media (min-width: 414px) and(max-width: 767.9px) {
	.portrait {
		img {
			width: calc(60% + 10 * var(--portrait-unit));
		}
	}
}
@media (max-width: 413.9px) {
	.portrait {
		img {
			width: calc(50% + 7.5 * var(--portrait-unit));
		}
	}
}
