@import "../../_init/colors/origin.scss";
.switcher {
	transition: 0.4s;
	display: block;
	width: 2em;
	height: 1em;
	border-radius: 0.5em;
	position: relative;
	cursor: pointer;
	&:active {
		transform: translateY(0.1rem);
	}
	&_checkbox[type="checkbox"] {
		display: none;
		&:not(:checked) {
			& + .switcher_slider {
				.icon {
					transform: translateX(0em) rotate(0deg);
					svg:nth-child(2) {
						opacity: 0;
					}
				}
			}
		}
		&:checked {
			& + .switcher_slider {
				.icon {
					transform: translateX(1em) rotate(180deg);
					svg:nth-child(1) {
						opacity: 0;
					}
					svg:nth-child(2) {
						transform: rotate(180deg);
					}
				}
			}
		}
	}
	&_slider {
		background-color: $dark;
		position: absolute;
		height: 1em;
		width: 2em;
		border-radius: 0.5em;
		transition: 0.4s;
		.icon {
			background-color: $light;
			height: 0.8em;
			width: 0.8em;
			border-radius: 0.4em;
			position: absolute;
			left: 0.1em;
			top: 0.1em;
			transition: 0.4s;
			z-index: 0;
			svg {
				transition: 0.4s;
				font-size: 0.6em;
				width: 100%;
				height: 100%;
				position: absolute;
				inset: 0;
				padding: 0.16666666666666666666667em;
			}
		}
	}
}
