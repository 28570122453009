@media (min-width: 962px) and (max-width: 1279.9px) {
	.about {
		&_quotes {
			grid-area: 3/8/5/14;
			font-family: visueltPro;
			font-size: calc(1.3 * var(--section-unit));
			font-weight: 500;
		}
		&_bio {
			grid-area: 5/8/10/14;
		}
		&_skills {
			grid-area: 10/8/12/14;
		}
	}
}

@media (min-width: 768px) and (max-width: 961.9px) {
	.about {
		&_quotes {
			grid-area: 6/2/7/8;
			justify-self: start;
			font-family: visueltPro;
			font-size: calc(1.5 * var(--section-unit));
			font-weight: 500;
		}
		&_bio {
			grid-area: 7/2/11/14;
		}
		&_skills {
			grid-area: 11/2/12/14;
			width: 100%;
			.list {
				flex-wrap: wrap;
				justify-content: space-between;
				&_item {
					flex-basis: 30%;
					.text {
						text-align: center;
					}
				}
			}
		}
	}
}
@media (max-width: 767.9px) {
	.about {
		&_quotes {
			grid-area: 3/2/5/14;
			justify-self: start;
			font-family: visueltPro;
			font-style: calc(2 * var(--section-unit));
			font-weight: 500;
		}
		&_bio {
			grid-area: 5/2/10/14;
		}
		&_skills {
			grid-area: 10/2/12/14;
			width: 100%;
			.list {
				flex-wrap: wrap;
				justify-content: space-between;
				&_item {
					flex-basis: 30%;
					.text {
						text-align: center;
					}
				}
			}
		}
	}
}
