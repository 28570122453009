@media (min-width: 1920px) {
	.works {
		&_content {
			.content {
				&_images {
					.devices {
						perspective: 4800px;
						--thickness: 13px;
					}
				}
			}
		}
	}
}
@media (min-width: 1280px) and (max-width: 1535.9px) {
	.works {
		&_content {
			.content {
				&_images {
					.devices {
						perspective: 3000px;
						--thickness: 8px;
					}
				}
			}
		}
	}
}
@media (min-width: 962px) and (max-width: 1279.9px) {
	.works {
		&_content {
			grid-template-columns: 33% 65%;
			grid-gap: 2%;
			.content {
				&_images {
					.devices {
						&_lid {
							transform: rotateX(0deg);
							animation: none;
						}
					}
				}
			}
		}
	}
}
@media (max-width: 961.9px) {
	.works {
		&_content {
			grid-area: 2/2/11/14;
			grid-template-columns: 100%;
			.content {
				&_images {
					.devices {
						&_lid {
							transform: rotateX(0deg);
							animation: none;
						}
					}
				}
			}
		}
		&_navigator {
			justify-self: center;
			align-self: center;
		}
	}
}
