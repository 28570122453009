//visuelt pro
@font-face {
	font-family: visueltPro;
	src: url(./visueltPro/VisueltPro-Medium.ttf);
	font-weight: 300;
}
@font-face {
	font-family: visueltPro;
	src: url(./visueltPro/VisueltPro-Bold.ttf);
	font-weight: 500;
}
//poppins
@font-face {
	font-family: poppins;
	src: url(./Poppins/Poppins-Light.ttf);
	font-weight: 100;
}
@font-face {
	font-family: poppins;
	src: url(./Poppins/Poppins-Medium.ttf);
	font-weight: 300;
}
@font-face {
	font-family: poppins;
	src: url(./Poppins/Poppins-Bold.ttf);
	font-weight: 500;
}
