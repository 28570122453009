@import "./_init/colors/colors";
@import "./_init/mixins/mixin";
*,
*::before,
*::after {
	position: relative;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
::selection {
	color: $coolGray-200;
	background-color: $coolGray-700;
}
:root {
	font-size: 10px;
	--animation-runTime-unit: 0.1s;
	--transition-runTime-unit: 0.1s;
}
body {
	margin: 0;
	#root {
		.app {
			width: 100vw;
			height: 100vh;
			transition: 0.4s;
			scroll-snap-type: y mandatory;
			overflow-x: hidden;
			overflow-y: scroll;
			.section {
				height: 100vh;
				width: 100vw;
				scroll-snap-align: center;
				scroll-snap-stop: always;
				display: grid;
				grid-template-columns: calc(2 * var(--unit)) repeat(12, 1fr) calc(
						2 * var(--unit)
					);
				grid-template-rows: repeat(12, 1fr);
			}
		}
	}
}
@import "./app.media.scss";
