@media (min-width: 768px) and (max-width: 961.9px) {
	.contact {
		&_title {
			grid-area: 5/2/7/14;
			z-index: 0;
			&::before {
				transition: 0.4s;
				font-size: 20em;
				transform: translate(-50%, 50%);
				top: 150%;
				left: 50%;
				z-index: -1;
				opacity: 0.5;
			}
		}
		&_paragraph {
			grid-area: 7/3/8/13;
		}
		&_list {
			grid-area: 8/2/9/14;
		}
	}
}
@media (max-width: 767.9px) {
	.contact {
		&_title {
			grid-area: 4/2/8/14;
			font-family: visueltPro;
			font-size: calc(1.8 * var(--section-unit));
			font-weight: 500;
			line-height: calc(2 * var(--section-unit));
			justify-self: left;
			text-align: left;
			z-index: 0;
			&::before {
				transition: 0.4s;
				transform: scale(14);
				transform-origin: right;
				right: -40%;
				bottom: -20%;
				z-index: -1;
			}
		}
		&_paragraph {
			grid-area: 8/2/10/9;
			text-align: justify;
		}
		&_list {
			grid-area: 10/2/12/14;
			justify-self: start;
		}
	}
}
